'use client'

import { FC, useContext, useEffect, useState } from 'react'
import { Loader } from '@papercutsoftware/pcds-react'
import { useSearchParams } from 'next/navigation'
import { getIdentityProviders, IdentityProvider, ProviderType } from '@/api/identityProviders'
import { createIDTokenCookie } from '@/api/idtokenCookie'
import { StyledFormWrapper } from '@/styles/firebaseUi.styles'
import LoginSignUpPageHeading from '@/components/LoginSignUpPageHeading/LoginSignUpPageHeading'
import SSOLogin from '@/components/SSOLogin/SSOLogin'
import OtherLoginOptions from '@/components/OtherLoginOptions/OtherLoginOptions'
import { ErrorMessage, toMessage } from '@/utils/errorCodes'
import { BackButton } from '@/components/BackButton/BackButton'
import { Product, ProductContext } from '@/context/product'
import { errorQueryParam, prefilledEmailAddressQueryParam } from '@/utils/pageurl/pageurl'
import { renderError } from '@/components/RenderAlert/RenderErrorAlert'
import { AuthFlow, AuthFlowContext } from '@/context/authflow'

interface Props {
  tenantId: string
}

const TenantLogin: FC<Props> = ({ tenantId }) => {
  const [identityProviders, setIdentityProviders] = useState<IdentityProvider[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isVisible, setIsVisible] = useState<boolean>(false)

  const searchParams = useSearchParams()
  const prefilledEmail = searchParams.get(prefilledEmailAddressQueryParam) ?? ''
  const urlErrorMsg = searchParams.get(errorQueryParam) ? toMessage(searchParams.get(errorQueryParam)!) : null
  const [error, setError] = useState<ErrorMessage | null>(urlErrorMsg)
  const product = useContext(ProductContext)
  const authFlow = useContext(AuthFlowContext)

  const fetchProviders = async (tenantId: string) => {
    const errorMessage = new ErrorMessage()
    try {
      const response = await getIdentityProviders(tenantId)

      if (response?.identityProviders) {
        return response.identityProviders
      }
      errorMessage.content = 'No identity providers enabled on tenant'
    } catch (e) {
      errorMessage.content = `Error fetching identity providers: ${JSON.stringify(e)}`

      return errorMessage
    } finally {
      setIsLoading(false)
    }

    return null
  }

  const shouldShowSSOLogin = (): boolean => {
    const hasSSO = identityProviders.some((idP) => idP.type === ProviderType.SAML)
    if (hasSSO) {
      const onlyHasSSO = identityProviders.every((idP) => idP.type === ProviderType.SAML)
      const authOption = searchParams.get('authOption')

      // If the organization has only SSO, or if the authOption is 'sso' or missing, show SSO login
      return onlyHasSSO || authOption === 'sso' || authOption == null
    }

    return false
  }

  useEffect(() => {
    if (!tenantId) {
      return
    }
    fetchProviders(tenantId).then((result) => {
      if (result instanceof ErrorMessage) {
        console.error(result)
        setError(result)

        return
      }
      if (result) {
        setIdentityProviders(result)
      } else {
        const errorMessage = new ErrorMessage()
        errorMessage.content = 'No identity providers enabled on tenant'
        setError(errorMessage)
      }
    })
  }, [tenantId, error, searchParams])

  if (isLoading) {
    return <Loader />
  }

  return (
    <StyledFormWrapper direction="column" spacing={2}>
      {isVisible && (
        <>
          {authFlow !== AuthFlow.Signup && prefilledEmail ? <BackButton /> : <></>}
          <LoginSignUpPageHeading />
        </>
      )}
      {shouldShowSSOLogin() ? (
        <SSOLogin
          tenantId={tenantId}
          providers={identityProviders}
          onAuthSuccess={(idToken) => onAuthSuccess(idToken, product)}
          onAuthFailure={onAuthFailure}
          onVisibilityChange={(visible) => {
            setIsVisible(visible)
          }}
          setAuthError={setError}
        />
      ) : (
        <OtherLoginOptions
          tenantId={tenantId!}
          providers={identityProviders}
          onAuthSuccess={(idToken) => onAuthSuccess(idToken, product)}
          onAuthFailure={onAuthFailure}
          prefilledEmail={prefilledEmail}
          onVisibilityChange={(visible) => {
            setIsVisible(visible)
          }}
          setAuthError={setError}
        />
      )}
      {error ? renderError(error) : <></>}
    </StyledFormWrapper>
  )
}

export const onAuthSuccess = async (idToken: string, product: Product) => {
  try {
    let redirectUrl = product.getRedirectUrl()

    console.log(`authentication success: productUrl=${redirectUrl}`)
    if (!isLocalHost(redirectUrl) && !isLocalHost(window.location.href) && hasSameDomainAsIDTokenCookie(redirectUrl)) {
      console.log(`creating IDToken cookie`)
      await createIDTokenCookie(idToken)
    } else {
      const redirectUrlWithIdToken = new URL(redirectUrl)
      redirectUrlWithIdToken.searchParams.set('idToken', idToken)
      redirectUrl = redirectUrlWithIdToken.toString()
    }
    window.location.href = redirectUrl
  } catch (e) {
    console.error(`failed handle auth success: ${e}`)
  }
}

// "https://au-staging.hive.papercut.software/someQueryParam=123" =? { tld: "software", sld: "papercut"}
const extractTopAndSecondDomains = (url: string): { tld: string; sld: string } => {
  try {
    const hostname = new URL(url).hostname
    const parts = hostname.split('.')
    const tld = parts[parts.length - 1] // top level domain, like "com" or "software"
    const sld = parts[parts.length - 2] // second level domain, like "papercut"

    return { tld, sld }
  } catch (error) {
    console.error(`Invalid URL: ${url}`)

    return { tld: '', sld: '' }
  }
}

const hasSameDomainAsIDTokenCookie = (url: string): boolean => {
  const windowURL = window.location.href

  const windowURLDomains = extractTopAndSecondDomains(windowURL)
  const urlDomains = extractTopAndSecondDomains(url)

  return windowURLDomains.tld === urlDomains.tld && windowURLDomains.sld === urlDomains.sld
}

const isLocalHost = (url: string) => {
  try {
    return new URL(url).hostname === 'localhost'
  } catch (e) {
    return false
  }
}

const onAuthFailure = (err: unknown) => {
  console.error(`onAuthFailure: ${err}`)
}

export default TenantLogin
